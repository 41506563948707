<template>
  <div class="animate__animated animate__fadeIn mb-12">
    <div class="flex w-full h-96 bg-service mb-6 justify-center">
      <div
        class="
          z-0
          bg-gray-900 bg-opacity-50
          w-full
          flex
          items-center
          justify-center
        "
      >
        <div class="z-10 text-4xl text-white px-4">
          Weekly Sunday Service
          <span v-if="serviceHeaderDate"
            >, {{ parseDate(serviceHeaderDate, "DD MMMM YYYY") }}</span
          >
        </div>
      </div>
    </div>
    <div class="container p-4 mx-auto bg-white mb-6" v-if="services">
      <div
        class="flex flex-wrap lg:space-x-4 lg:justify-start justify-center"
        v-for="(tr, index) in services"
        :key="index"
      >
        <div class="p-2">
          <img
            id="svc-img-bm"
            :src="require('@/assets/images/' + tr.poster)"
            class="lg:w-80 w-full object-scale-down shadow-md"
            alt=""
          />
        </div>

        <div class="grid-grid-cols-1">
          <div class="flex flex-col lg:items-start items-center mb-12">
            <div class="lg:text-left text-center text-3xl text-gray-700">
              {{ tr.name }}
            </div>
            <div class="text-xl mb-4 lg:text-left text-center">
              Topic: {{ tr.topic }}
            </div>
            <div class="font-bold text-sm">
              <i class="fas fa-calendar"></i> Date
            </div>
            <div class="mb-4 text-xl">
              {{ parseDate(tr.date_time, "DD MMM YYYY") }}
            </div>
            <div class="font-bold text-sm">
              <i class="fas fa-clock"></i> Time
            </div>
            <div class="mb-4 text-xl">
              {{ parseDate(tr.date_time, "h:mm A") }}
            </div>
            <div class="font-bold text-sm">
              <i class="fas fa-link"></i> Streaming Links
            </div>
            <div class="font-bold text-xl">
              <ul>
                <li class="pl-0">youtube.com/c/BEMOliveGarden</li>
                <li class="pl-0">bemolivegarden.online.church</li>
                <li class="pl-0">facebook.com/BEMOliveGarden</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t-2">
        <h3 class="text-4xl">Tell Us What You Think</h3>
        <p class="mb-6">
          Please fill in your personal details below. Response will only be
          allowed after the service ended.
        </p>
      </div>
    </div>
    <div v-else class="container lg:p-0 p-4 mx-auto bg-white mb-6">
      Fetching services... <i class="fas fa-circle-notch-fa-spin"></i>
    </div>
    <div class="service-response py-6">
      <div class="container mx-auto p-4">
        <div class="grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div>
            <span class="required">Name / Head of Family Name</span>
            <input
              type="text"
              v-model="headOfFamily"
              class="w-full border p-2"
            />
          </div>
          <div>
            Current Location
            <input
              type="text"
              v-model="currentLocation"
              class="w-full border p-2"
              placeholder="City, state, country..."
            />
          </div>
          <div>
            Email
            <input type="email" v-model="email" class="w-full border p-2" />
          </div>
          <div>
            <span class="required">Viewing Location</span>
            <v-select
              v-model="viewingLocation"
              :options="viewingLocationList"
              label="id"
              class="bg-white"
              :reduce="(location) => location.id"
            ></v-select>
          </div>
          <div>
            <span class="required"
              >How many viewers are with you including yourself?</span
            >
            <input
              type="number"
              v-model="viewerCount"
              class="w-full border p-2"
              placeholder="Number of family mambers or friends..."
            />
          </div>
          <div>
            <span class="required"> Select Service</span>
            <v-select
              v-model="selectedService"
              :options="services"
              label="name"
              :reduce="(service) => service.id"
              class="bg-white"
            ></v-select>
          </div>
          <div>
            Remarks or Comments (Or how we can reach you)
            <textarea
              class="w-full border resize-none p-2"
              cols="30"
              rows="5"
              v-model="remarks"
              placeholder="Leave you contact information here if you are not a member"
            ></textarea>
          </div>
          <div>
            Response Code (Optional, from previous request)
            <input
              maxlength="10"
              type="text"
              class="w-full p-2"
              v-model="uniqCodeUpdate"
              placeholder="Provide autogenerated code from previous request to update your response"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto p-4 mt-12">
      <div class="flex flex-wrap gap-5 lg:justify-start justify-center">
        <button
          id="submit-button"
          @click="submitResponse()"
          class="
            border
            shadow-md
            bg-green-700
            hover:bg-green-400
            text-white
            rounded-md
            p-2
            w-32
          "
        >
          Submit
        </button>
        <button
          @click="resetInput"
          class="
            border
            shadow-md
            bg-gray-700
            hover:bg-gray-400
            text-white
            rounded-md
            p-2
            w-32
          "
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from "vue-select";
export default {
  methods: {
    getServices() {
      axios({
        url: `${process.env.VUE_APP_URL}/api/service-feedback`,
        method: "GET",
      })
        .then((response) => {
          this.services = response.data;
          this.serviceHeaderDate = this.services[0].date_time;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetInput() {
      this.headOfFamily =
        this.currentLocation =
        this.remarks =
        this.viewingLocation =
        this.email =
          "";
      this.viewerCount = 1;
      this.selectedService = "";
      this.uniqCodeUpdate = "";
    },
    submitResponse() {
      if (
        this.headOfFamily.trim().length == 0 ||
        this.viewingLocation.trim().length == 0 ||
        this.selectedService.trim().length == 0
      ) {
        this.$swal({
          icon: "warning",
          title: "Service Feedback",
          text: "Please make sure you have filled all the required input marked with '*'",
        });
        return;
      }

      if (this.viewerCount < 1) {
        this.$swal({
          icon: "warning",
          title: "Service Feedback",
          text: "Viewer can't possibly be 0 is it?",
        });
        return;
      }

      this.$recaptchaLoaded().then(() => {
        console.log("recaptcha loaded");
        this.$recaptcha("service_feedback").then((token) => {
          this.postResponse(token);
        });
      });
    },
    postResponse(token) {
      axios({
        url: `${process.env.VUE_APP_URL}/api/service-feedback/store`,
        method: "POST",
        data: {
          headOfFamily: this.headOfFamily,
          currentLocation: this.currentLocation,
          viewingLocation: this.viewingLocation,
          viewerCount: this.viewerCount,
          remarks: this.remarks,
          serviceId: this.selectedService,
          uniqCode: this.uniqCodeUpdate,
          email: this.email,
          token: token,
        },
      })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Service Feedback",
            text: response.data.message,
            allowOutsideClick: false,
          });
          this.resetInput();
        })
        .catch((error) => {
          let message = `Your response for "${this.serviceName}" has not recorded.`;
          if (error.hasOwnProperty("response")) {
            message = error.response.data.message;
          }

          this.$swal({
            icon: "error",
            title: "Problem Processing Response",
            text: message,
          });
        })
        .finally(() => {});
    },
  },
  components: {
    "v-select": VueSelect,
  },
  name: "sundaySvc",
  data() {
    return {
      serviceHeaderDate: "",
      uniqCodeUpdate: "",
      services: [],
      selectedService: "",
      headOfFamily: "",
      currentLocation: "",
      viewingLocation: "",
      viewerCount: 1,
      remarks: "",
      email: "",
      serviceName: "Combined Service - Church Roadmap Program Launching",
      viewingLocationList: [
        { id: "Home", name: "Home (Rumah)" },
        { id: "Workplace", name: "Workplace (Tempat Kerja)" },
        { id: "Public", name: "Public (Tempat Awam)" },
        { id: "Quarantine", name: "Quarantine Center (Pusat Quarantine)" },
        { id: "Hospital", name: "Hospital" },
      ],
    };
  },
  mounted() {},
  created() {
    this.getServices();
    console.log(this.$i18n.locale);
  },
};
</script>

<style scoped>
.bg-service {
  background: url("~@/assets/images/sunday.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.service-response {
  background: #f2f5f3;
  border: 4px 0;
}
</style>